/* DownloadButton.css */
.download-button {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    margin: 5px;
  }
  
  .download-button img {
    vertical-align: middle;
    margin-right: 5px;
    width: 50vw
  }

  .visible {
    transform: translateX(0);
    opacity: 1;
  }
  
  