html, body {
  background-color: var(--slightwhite);
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5vh;  /* Check if this padding is necessary, or could be affecting layout */
  overflow-x: hidden;
  overflow-y: scroll;  /* Ensures that the body can always scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

:root {
  --primary: #6C441A;
  --secondary: #f48e28;
  --bold: #CC7722;
  /* --lightbold: #D47F2A; */
  --lightbold: #ed8e2f;
  --dark: #855d42;
  --light: #dbc5b6;
  --white: #FFFFFF;
  --black: #000000;
  --grey: #D3D3D3;
  --offwhite: #EDE9E6;
  --slightwhite: #FCFCFC
  /* --offwhite: #e7e3df; */
  /* --offwhite: #e3dfd9; */
}

.colliding-block {
  position: relative;
  width: 100%;
  height: 60vh; /* or a specific height if needed */
  overflow: hidden; /* Ensures children stay within the block */
  /* padding-bottom: 5vh */
  margin-top: -10vh;
}

.colliding-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.colliding-block img {
  position: absolute;
  max-width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 1s ease, transform 2.5s ease;
}

/* Phone screenshot */
.colliding-block img:first-child {
  top: 10%;
  /* right: 32vw; */
  transform: translateX(400%);
  height: 50vh;
  width: auto;
  z-index: 2
}
/* @media (max-width: 768px) {
  .colliding-block img:first-child {
    right: -10vw
  }
} */

/* Position block */
.colliding-block img:nth-child(2) {
  top: 20%;
  /* left: 38vw; */
  transform: translateX(-100%);
  height: 20vh;
  width: auto;
  z-index: 1
}
/* @media (max-width: 768px) {
  .colliding-block img:nth-child(2) {
    left: 10vw
  }
} */

.colliding-block.visible img {
  opacity: 1; /* Fade in when in view */
  transform: translateX(0); /* Move back to original position */
}

/* Additional styles for responsiveness */

.block-left {
  opacity: 0;
  transform: translateX(-15vw); /* Use 100vw to ensure it's only as wide as the viewport */
  transition: transform 1s ease-out, opacity 2s ease-out;
  margin-bottom: 10vh;
}

.block-right {
  opacity: 0;
  transform: translateX(-15vw); /* Use 100vw to ensure it's only as wide as the viewport */
  transition: transform 1s ease-out, opacity 2s ease-out;
  margin-bottom: 10vh;
}

.lottie-wrapper {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  min-width: 40%;
  margin: 0 auto; 
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.lottie-container {
  margin-top: 10vh;
  height: 30vh;
}

.image-container, .video-container {
  max-width: 60vw;
  min-width: 40vw;
  max-height: 40vh;
  min-height: 25vh;
  padding-top: 5vh;
}


.parallax-block {
  max-width: 100%;
  position: relative;
  height: 60vh; /* Adjust as needed */
  /* background: 
    linear-gradient(to bottom, var(--offwhite) 0%, var(--bold) 50%, var(--offwhite) 100%); */
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: contain; */
  overflow: hidden;
}

.foreground-image-one {
  position: absolute;
  top: 15vh;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  height: auto; /* Maintain aspect ratio */
  width: 50vh;
  max-width: 100vw;
  z-index: 2; /* Above the text */
}
@media (max-width: 767px) {
  .foreground-image-one {
    top: 20vh;
    width: 45vh;
    left: 30%;
  }
}

.foreground-image-two {
  position: absolute;
  top: 20vh;
  right: 48%;
  /* transform: translate(-50%, -50%); */
  height: auto; /* Maintain aspect ratio */
  width: 50vh;
  max-width: 100vw;
  z-index: 2; /* Above the text */
}
@media (max-width: 767px) {
  .foreground-image-two {
    right: 30%;
  }
}

.foreground-image-three {
  position: absolute;
  top: 20vh;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  height: auto; /* Maintain aspect ratio */
  width: 45vh;
  max-width: 100vw;
  z-index: 2; /* Above the text */
}
@media (max-width: 767px) {
  .foreground-image-three {
    width: 45vh;
    left: 40%;
  }
}

.foreground-image-four {
  position: absolute;
  top: 15vh;
  right: 50%;
  /* transform: translate(-50%, -50%); */
  height: auto; /* Maintain aspect ratio */
  width: 35vh;
  max-width: 100vw;
  z-index: 2; /* Above the text */
}

.parallax-text-one {
  position: absolute;
  top: 35vh;
  left: 35%; 
  color: #FFFFFF; /* White color */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900; /* Bold text */
  z-index: 3; /* Below the foreground image */
  font-size: 9vh; /* Adjust the size as needed */
  width: 45%; /* Adjust the width as needed */
  text-align: left;
  text-shadow: 2px 2px 2px var(--black);
  white-space: pre-line;
}
@media (max-width: 767px) {
  .parallax-text-one {
    left: 10%;
  }
}


.parallax-text-two {
  position: absolute;
  top: 35vh;
  right: 30%; /* Adjust to move text further left if needed */
  /* transform: translateY(-10vh); */
  color: #FFFFFF; /* White color */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900; /* Bold text */
  z-index: 3; /* Below the foreground image */
  font-size: 9vh; /* Adjust the size as needed */
  width: 45%; /* Adjust the width as needed */
  text-align: right;
  text-shadow: 2px 2px 2px var(--black);
  white-space: pre-line;
}
@media (max-width: 767px) {
  .parallax-text-two {
    right: 20%;
  }
}


.parallax-text-three {
  position: absolute;
  top: 35vh;
  left: 35%;
  color: #FFFFFF; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900;
  z-index: 3; 
  font-size: 9vh;
  width: 45%; 
  text-align: left;
  text-shadow: 2px 2px 2px var(--black);
  white-space: pre-line;
}
@media (max-width: 767px) {
  .parallax-text-three {
    left: 10%;
  }
}

.parallax-text-four {
  position: absolute;
  top: 35vh;
  right: 30%; /* Adjust to move text further left if needed */
  /* transform: translateY(-10vh); */
  color: #FFFFFF; /* White color */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900; /* Bold text */
  z-index: 3; /* Below the foreground image */
  font-size: 9vh; /* Adjust the size as needed */
  width: 45%; /* Adjust the width as needed */
  text-align: right;
  text-shadow: 2px 2px 2px var(--black);
  white-space: pre-line;
}
@media (max-width: 767px) {
  .parallax-text-four {
    right: 20%;
  }
}

.header-block {
  font-size: 64px;
  font-family: 'Roboto Condensed', sans-serif;
  background: linear-gradient(to bottom, var(--secondary), var(--secondary), var(--secondary));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  justify-content: space-around;
  position: relative;
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

.custom-o img {
  height: 1em;  /* Adjusts the height of the image to match the text size */
  vertical-align: middle;  /* Aligns the image vertically with the text */
  display: inline-block;
  margin-right: -0.2em;
  margin-left: -0.2em;
  animation: wiggle 1s ease-in-out infinite; /* Applies the wiggle animation */
}

.block-header {
  flex: 1;
  color: var(--secondary);
  text-align: center;
  font-size: 2em; 
  font-family: sans-serif;
  margin-right: 5vw;
  margin-left: 5vw;
  position: relative;
  z-index: 2;
  margin-top: 0vh;
  padding-left: 20;
  padding-right: 20;
  /* text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); */
}

.block-textbox {
  border-radius: 20px;
  background-color: #FFFFFF;
  opacity: 0.8;
  border: 1px solid var(--secondary);
  padding: 20px;
  margin-right: 10vw;
  margin-left: 10vw;
}

.block-text {
  flex: 1;
  color: var(--primary); 
  text-align: center;
  font-weight: 800;
  line-height: 1.4;
  font-size: 1.2em; 
  font-family: sans-serif;
  white-space: pre-line;
  position: relative;
  z-index: 2;
  margin-top: 0vh;
}

.block-subtext {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.75s ease, transform 0.75s ease;
  flex: 1;
  color: var(--primary); 
  text-align: center;
  font-weight: 800;
  line-height: 1.4;
  font-size: 1.2em; 
  font-family: sans-serif;
  white-space: pre-line;
  position: relative;
  z-index: 2;
  margin-top: 0vh;
}
.block-subtext.visible {
  opacity: 1;
  transform: translateY(0);
}

.visible {
  transform: translateX(0);
  opacity: 1;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  max-width: 600px;
  align-items: center;
  /* background: var(--light);  */
  border-radius: 10px;
  border-width: 5px;
  border-color: var(--secondary);
  /* box-shadow:
    8px 8px 15px var(--primary),
    -8px -8px 15px var(--white); */
  padding: 20px;
}

.form-header {
  flex: 1;
  color: var(--secondary); 
  text-align: center;
  font-weight: 900;
  font-size: 2em;
  line-height: 1.4;
  font-family: sans-serif;
  margin-right: 10%;
  margin-left: 10%;
  white-space: pre-line;
  position: relative;
  z-index: 2;
  margin-top: 0vh;
}

.form-text {
  flex: 1;
  color: var(--primary); 
  text-align: center;
  font-weight: 800;
  line-height: 1.4;
  font-size: 1.2em;
  font-family: sans-serif;
  margin-right: 5%;
  margin-left: 5%;
  white-space: pre-line;
  position: relative;
  z-index: 2;
  margin-top: 0vh;
  /* text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); */
}
@media (max-width: 768px) {
  .form-text {
    font-size: 1em;
  }
}

.form-input {
  padding: 15px;
  width: 90%;
  font-weight: 800;
  border: none; 
  border-radius: 10px; 
  /* background: var(--light); */
  color: var(--secondary);
  box-shadow:
    inset 1px 1px 4px var(--primary),
    inset -2px -2px 6px var(--offwhite); 
  outline: none;
  z-index: 2
}

.form-submit {
  padding: 15px;
  cursor: pointer;
  font-weight: 900;
  font-size: 1.5em;
  width: 50%;
  font-family: sans-serif;
  border: none;
  margin-top: 10px;
  border-radius: 10px; 
  background: var(--secondary);
  color: var(--white);
  box-shadow:
    2px 2px 6px var(--primary),
    -2px -2px 4px var(--offwhite);
  outline: none; 
}

/* Adding :active state for when the button is clicked */
.form-submit:active {
  box-shadow:
    inset 2px 2px 4px var(--primary), /* "Pushed inwards" effect */
    inset -2px -2px 4px var(--offwhite);
  transform: scale(0.97); /* Slightly scale down the button to enhance the effect */
}

.floating-button {
  z-index: 1;
  position: fixed;
  top: 10vh;
  right: 2.5vw;
  background-color: var(--secondary);
  color: var(--white);
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
}
@media (max-width: 768px) {
  .floating-button {
    padding: 10px 15px;
  }
}



.floating-button.visible {
  visibility: visible;
  opacity: 0.9;
  transform: translateY(20px);
}

.floating-button.hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
}

.floating-button:hover {
  background-color: var(--lightbold);
  transform: scale(1.1) translateY(10px);
}

.floating-button .launch-day {
  font-size: 1.2em;
}
@media (max-width: 768px) {
  .floating-button .launch-day{
    font-size: 0.8em;
  }
}

.floating-button .free-for-life {
  font-size: 1em;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .floating-button .free-for-life{
    font-size: 0.66em;
  }
}

.video-embed-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

