/* CoffeeBean.css */
@keyframes fall-and-spin {
    from {
      transform: translateY(-100vh) rotate(0deg); /* Starts off-screen above */
    }
    to {
      transform: translateY(100vh) rotate(var(--rotation)); /* Ends off-screen below */
    }
  }
  
  .coffee-bean {
    position: fixed;
    width: 50px; /* Adjust size as needed */
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  